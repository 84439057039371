<template>
  <header role="banner" class="z-50 flex w-full flex-row justify-between py-5">
    <div class="flex w-1/5 pl-5 md:w-1/12">
      <button
        v-if="routeName[0] !== 'login'"
        :aria-label="$t('c_Header.Go to homepage')"
        :class="handleResponsiveClasses('logo')"
        @click.prevent="goToHome()"
      >
        <FontAwesome icon="home" />
      </button>

      <button
        :class="handleResponsiveClasses('back')"
        :aria-label="$t('g_General.Back to previous page')"
        @click.prevent="goToHome()"
      >
        <FontAwesome icon="arrow-left" class="h-4 w-auto" />
      </button>
    </div>

    <div class="w-4/5 grow place-self-center text-center md:w-10/12">
      <div
        id="headerTeleport"
        name="header-title"
        class="flex items-center justify-center text-lg"
      >
        <!-- <img
          src="/images/tue-logo.png"
          :alt="$t('c_Header.TU/e logo')"
          role="presentation"
          class="h-4 w-auto"
        />
        <h2 class="ml-2 inline-block font-bold tracking-tighter">beAPP</h2> -->
      </div>
    </div>

    <div class="flex w-1/5 items-center justify-end pr-5 md:w-1/12">
      <WtDropdown v-if="isAuthenticated">
        <template #button>
          <FontAwesome
            :icon="hasElevatedAccess ? 'user-cog' : 'user'"
            class="h-4 w-auto"
          />
        </template>
        <template #content>
          <NuxtLink
            v-if="
              (hasElevatedAccess && routeName[0] === 'index') ||
              (isAdministrator && routeName[0] !== 'teacher')
            "
            class="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-700"
            to="/teacher"
            role="menuitem"
          >
            <FontAwesome icon="user-cog" class="mr-2 h-4 w-5" />
            {{ $t('g_General.Manage concepts') }}
          </NuxtLink>
          <NuxtLink
            v-else-if="hasElevatedAccess"
            class="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-700"
            to="/"
            role="menuitem"
          >
            <FontAwesome icon="glasses" class="mr-2 h-4 w-5" />
            {{ $t('g_General.Student view') }}
          </NuxtLink>
          <NuxtLink
            v-if="isAdministrator && routeName[0] !== 'admin'"
            class="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-700"
            to="/admin"
            role="menuitem"
          >
            <FontAwesome icon="users" class="mr-2 h-4 w-5" />
            {{ $t('g_General.Administrator view') }}
          </NuxtLink>
          <NuxtLink
            v-if="isAdministrator && routeName[0] === 'admin'"
            class="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-700"
            to="/"
            role="menuitem"
          >
            <FontAwesome icon="glasses" class="mr-2 h-4 w-5" />
            {{ $t('g_General.Student view') }}
          </NuxtLink>
          <a
            href="mailto:beapp@tue.nl"
            class="block cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-700"
            role="menuitem"
          >
            <FontAwesome icon="handshake-angle" class="mr-2 h-4 w-5" />
            {{ $t('g_General.Contact us') }}
          </a>
          <span
            class="block cursor-pointer px-4 py-2 text-red-500 hover:bg-gray-100 hover:text-red-700"
            role="menuitem"
            @click="logOut()"
          >
            <FontAwesome icon="right-from-bracket" class="mr-2 h-4 w-5" />
            {{ $t('g_General.Log out') }}
          </span>
        </template>
      </WtDropdown>
    </div>
  </header>
</template>

<script setup>
const { isAuthenticated, userRoles } = storeToRefs(useUserStore())
const { clearUser } = useUserStore()
const { $bus } = useNuxtApp()
const router = useRouter()
const route = useRoute()
const hasElevatedAccess = computed(() => {
  return userRoles.value.includes('teacher')
})
const isAdministrator = computed(() => {
  return userRoles.value.includes('administrator')
})
const routeName = computed(() => {
  if (!route.name) {
    return ['']
  }

  return route.name.split('___')
})

const logOut = () => {
  clearUser()
  router.push('/login')
}

const handleResponsiveClasses = (type) => {
  const isUserEligible =
    !isAuthenticated.value ||
    (hasElevatedAccess && routeName.value.includes('teacher')) ||
    (!hasElevatedAccess && routeName.value.includes('index'))

  if (typeof type === 'string' && type === 'logo') {
    return isUserEligible ? '' : 'hidden md:block'
  }

  return isUserEligible ? 'hidden' : 'md:hidden'
}

const goToHome = () => {
  if (
    route.path === '/teacher/concept' ||
    route.path.startsWith('/teacher/test-questions')
  ) {
    $bus.$emit('goToHomeClicked')
  } else if (hasElevatedAccess) {
    router.push('/teacher')
  } else {
    router.push('/')
  }
}
</script>
